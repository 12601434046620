$(window).on('load',function(){
	$(document).ready(function(){

        $(".faq_title").click(function () {
            $(this).toggleClass('active');
            $(this).next(".faq_answer").stop().slideToggle();
            $(this).parent(".faq_box").toggleClass('active');
        });
        $(".faq-button").click(function () {
            $(this).prev(".faq-text").stop().slideToggle();
            $(this).parent(".faq-wrapper").toggleClass('active');
        });

	});


});


